import { Component } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UserData, UserSettings} from '../abstract/users';
import {commonImports} from "../app.imports";
import {MatDialog} from "@angular/material/dialog";
import {PopUpComponent} from "../common/pop-up/pop-up.component";

@Component({
    selector: 'app-user-settings',
    standalone: true,
    imports: [...commonImports],
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent {
    userId = '';
    form: FormGroup = null!;
    referrals: {id: number, name: string}[] = [];
    settings: UserSettings = null!;

    get isFormValid(): boolean {
      return this.form?.valid || false;
    }

    constructor(
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private userService: UserData,
        private title: Title,
        private readonly dialogService: MatDialog,
        formBuilder: FormBuilder) {
          this.route.paramMap.subscribe(params => {
            this.userId = params.get('userId')!;
            this.title.setTitle(`Configure settings for ${this.userId} | Burvix Bot`);

            this.form = formBuilder.group({
              'userId': [this.userId, Validators.required],
              'referralAwardPercentage': ['', []],
              'invoiceProfitBoundary': ['', []],
              'invoicePercentage': ['', []],
              'referralId': [null]
            });

            this.loadData();
          });
    }

    loadData() {
      this.loadingService.openLoader();
      const referrals = this.userService.getAllReferrals();
      const settings = this.userService.getUserConfigurations(this.userId);

      combineLatest([referrals, settings]).pipe(
        finalize(() => this.loadingService.closeLoader())
      ).subscribe(res => {
        this.referrals = Object.keys(res[0]).map(r => ({id: parseInt(r), name: res[0][r]}));
        this.settings = res[1];
        this.referrals.sort((a, b) => a.name > b.name ? 1 : -1);

        this.form.patchValue(res[1]);
      });
    }

    submitSettings() {
      this.dialogService.open(PopUpComponent, {
        data: {
          title: 'Are you sure?',
          message:'You are going to update settings.',
          cancelButtonText: 'Cancel',
          saveButtonText: 'Update',
        }
      }).afterClosed().subscribe(res => {
        if(!res) return;

        const invoice = this.userService.saveUserConfigurations(this.form.value);

        this.loadingService.openLoader();
        combineLatest([invoice]).pipe(
          finalize(() => this.loadingService.closeLoader())
        ).subscribe(_ => {
          alert('Settings has been update');
          this.loadData();
        });
      });
    }
 }
