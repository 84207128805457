import {afterNextRender, Component} from '@angular/core';
import { commonImports } from '../app.imports';
import { ReferralInfo, UserData } from '../abstract/users';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoadingService } from '../services/loading.service';
import { combineLatest, finalize } from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopUpComponent} from "../common/pop-up/pop-up.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-referral-program',
  standalone: true,
  imports: [...commonImports],
  templateUrl: './referral-program.component.html',
  styleUrl: './referral-program.component.scss'
})
export class ReferralProgramComponent {

  userId!: string | null;
  data!: ReferralInfo;
  link: string = "https://burvix.io?refCode=";
  form: FormGroup;
  titleMap: {[key: string]: string} = {
    'withdrawal': 'Withdrawal',
    'discountinvoice': 'Discount'
  };

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private userService: UserData,
    private loadingService: LoadingService,
    private clipboard: Clipboard,
    private readonly dialogService: MatDialog,
    formBuilder: FormBuilder
  ) {
    afterNextRender(() => {
      window.scrollTo(0, 0);
    })
    this.titleService.setTitle('Referral Program | Burvix Traders');
    this.form = formBuilder.group({
      'amount': ['', Validators.required]
    });
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userId');
      this.loadData();
    });
  }


  loadData() {
    this.loadingService.openLoader();

    const referral = this.userId
      ? this.userService.getUserReferralInfo(this.userId)
      : this.userService.getReferralInfo();

    combineLatest([referral]).pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe((res => {
      this.data = res[0];
      this.link += this.data.referralId;
    }));
  }

  formatNumber(i: number, decimalNumbers: number = 2) {
    return new Intl.NumberFormat('en', { maximumFractionDigits: decimalNumbers}).format(i);
  }


  private copyFunc() {
    this.clipboard.copy(this.link);
  }

  get currentBalance(): number {
    return (this.data?.totalReward ?? 0) - (this.data?.receivedReward ?? 0);
  }

  getTransactionType(type: string) {
    type = type.toLowerCase();
    return this.titleMap[type]
      ? this.titleMap[type]
      : type;
  }

  withdrawMoney() {
    this.dialogService.open(PopUpComponent, {
      data: {
        title: 'Are you sure?',
        message:'You are going to withdraw referral money.',
        cancelButtonText: 'Cancel',
        saveButtonText: 'Withdraw',
      }
    }).afterClosed().subscribe(res => {
      if(!res) return;

      const invoice = this.userService.withdrawReferralBalance(this.userId as string, this.form.value.amount);

      this.loadingService.openLoader();
      combineLatest([invoice]).pipe(
        finalize(() => this.loadingService.closeLoader())
      ).subscribe(_ => {
        alert('Balance updated.');
        this.loadData();
      });
    });
  }

  socialClickShare(name: string){
    switch(name){
      case "telegram":
        window.open('https://t.me/share/url?url=https://burvix.io?refCode=' + this.data?.referralId, '_blank');
        break;
      case "twitter":
        window.open('https://twitter.com/intent/post?text=Refer%20your%20friends%20to%20get%20started.&url=https://burvix.io?refCode=' + this.data?.referralId, '_blank');
        break;
      case "facebook":
        window.open('https://www.facebook.com/sharer/sharer.php?t=Refer%20your%20friends%20to%20get%20started.&u=https://burvix.io?refCode=' + this.data?.referralId, '_blank');
        break;
      case "whatsapp":
        window.open('https://api.whatsapp.com/send?text=Refer%20your%20friends%20to%20get%20started.%20https%3A%2F%2Fhttps://burvix.io?refCode=' + this.data?.referralId, '_blank');
        break;
      case "copy":
        this.copyFunc();
        break;
    }
  }
}
