<ngx-personal-area-common>
  <button (click)="filterUserMarketing()" *ngIf="!applyFilter">Display only for marketing</button>
  <div class="table-block">

    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Telegram</th>
          <th>Referral</th>
          <th>Bots</th>
          <th>Last Order</th>
          <th>Invoice, %</th>
          <th>Stop Bot, %</th>
          <th>Referral, %</th>
          <th></th>
        </tr>
      </thead>
      <tbody>

        @if(users().length > 0){
          @for (item of users(); track item; let i = $index) {
            <tr [ngClass]="{ 'even-row': i % 2 !== 0 }">
              <th>{{item.name}}</th>
              <th>{{item.email}}</th>
              <th><a *ngIf="item.telegramAccount" href="https://t.me/{{item.telegramAccount.substring(1)}}" target="_blank">{{ item.telegramAccount }}</a></th>
              <th>{{item.referral}}</th>
              <th>{{item.bots}}</th>
              <th>
                <div class="date-block">
                  <p>{{ item.lastOrderDate | date:'dd.MM.yyyy HH:mm:ss' }}</p>
                </div>
              </th>
              <th>{{item.settings.invoicePercentage}}</th>
              <th>{{item.settings.invoiceProfitBoundary}}</th>
              <th>{{item.settings.referralAwardPercentage}}</th>
              <th>
                <button (click)="selectUser(item)">View</button>
                <button (click)="configureUser(item)">Configure</button>
              </th>
            </tr>
          }
        } @else{
          <th class="no-data" colspan="7"><p>No Data</p></th>
        }

      </tbody>
    </table>

  </div>

</ngx-personal-area-common>
