<ngx-personal-area-common>
    <div class="payments">
        <h4>Payments History</h4>
      <div class="container">
        <table class="table-payments">
          <thead>
          <tr>
            <th>Date</th>
            <th>Trade period</th>
            <th>Client's profit</th>
            <th>Invoice amount</th>
            <th>Exchange</th>
            <th>Trading pair</th>
            <th>Status</th>
            <th>Payment date</th>
            <th class="action">Action</th>
          </tr>
          </thead>
          <tbody>
          @for (item of items; track item.invoiceId; let idx = $index, e = $even) {
          <tr [class]="{'another': !e}">
                        <th>{{item.createdOn | date:'dd.MM.yyyy'}}</th>
                        <th>{{item.begin | date:'dd.MM.yy' }} - {{item.end | date:'dd.MM.yy'}}</th>
                        <th>
                            <div class="inner-block">
                                <p>{{item.totalProfit}}</p>
                                <p class="baseCoin">{{item.baseCoin}}</p>
                            </div>
                        </th>
                        <th>
                            <div class="inner-block">
                                <p>{{item.invoiceAmount}}</p>
                                <p class="baseCoin">{{item.baseCoin}}</p>
                            </div>
                        </th>
                        <th>{{item.exchangeName}}</th>
                        <th>{{item.cryptoCoin}}/{{item.baseCoin}}</th>
                        <th [class]="item.isPaid ? 'paid': 'unpaid'">{{item.isPaid ? 'Paid': 'Unpaid'}}</th>
                        <th>{{item.paidOn | date:'dd.MM.yyyy'}}</th>
                        @if(item.isPaid){
                          <th><button class="paid-invoice">Paid</button></th>
                        } @else {
                          <th>
                            <a [href]="item.paymentLink" class="pay-invoice" target="_blank">Pay invoice</a>
                            @if(isAdmin) {
                              <button (click)="completePayment(item.invoiceId)">Complete</button>
                              <button (click)="deletePayment(item.invoiceId)">Delete</button>
                            }
                          </th>
                        }
          </tr>
          }
          </tbody>
        </table>
      </div>
    </div>

    @if(items.length > 11){
        <ngx-pagination
            style="display: block; margin-bottom: 30px;"
            [pagesCount]="pageSize"
            [currentPage]="totalPages"
            (pageSelected)="loadPage($event)"
            >
        </ngx-pagination>
    }
</ngx-personal-area-common>

