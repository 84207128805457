<ngx-personal-area-common>
  <div *ngIf="form" [formGroup]="form" style="max-width: 1200px">

    <div class="step">
      <div class="head-of-page">
        <h1>Settings</h1>
      </div>
    </div>

    <div class="api-input">
      <div class="api-input-inner">
        <div class="input-inner">
          <div>
            <h4>Invoice pay percentage</h4>
            <input type="number" formControlName="invoicePercentage" maxlength="250" step=".01">
          </div>
          <div>
            <h4>Stop bot profit percentage</h4>
            <input type="number" formControlName="invoiceProfitBoundary" maxlength="250" step=".01">
          </div>
          <div>
            <h4>Referral profit percentage</h4>
            <input type="number" formControlName="referralAwardPercentage" maxlength="250" step=".01">
          </div>
          <div>
            <h4>{{this.userId}} referral:</h4>
            <mat-select formControlName="referralId">
              <mat-option style="color:white" >No Referral</mat-option>
              @for (referralId of referrals; track referralId) {
                <mat-option [value]="referralId.id">{{referralId.name}}</mat-option>
              }
            </mat-select>
          </div>
        </div>
        <div class="text-api-input-inner">
        </div>
      </div>
    </div>

    <div class="portfolio">
      <div class="bnt-div">
        <button class="cnt-btn" [disabled]="!isFormValid" (click)="submitSettings()">Update settings</button>
      </div>
    </div>
  </div>
</ngx-personal-area-common>
