import {afterNextRender, Component, inject} from '@angular/core';
import { commonImports } from '../app.imports';
import { BotPairLookup, PaymentInfo, UserData } from '../abstract/users';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { Title } from '@angular/platform-browser';
import { combineLatest, finalize } from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {PopUpComponent} from "../common/pop-up/pop-up.component";

@Component({
  selector: 'app-payments',
  standalone: true,
  imports: [...commonImports],
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss'
})
export class PaymentsComponent {
  userId!: string;
  totalPages = 0;
  pageSize = 10;
  items: PaymentInfo[] = [];
  exchanges!: BotPairLookup[];
  selectedExchange!: BotPairLookup;
  readonly dialog = inject(MatDialog);

  isAdmin: Boolean = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserData,
    private loadingService: LoadingService,
    private titleService: Title
  ) {
    afterNextRender(() => {
      window.scrollTo(0, 0);
    });
    this.titleService.setTitle('Payments | Burvix Traders');
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userId') || '';
      this.isAdmin = !!this.userId;
      this.loadPage(1);
    });
  }

  formatPair(item: any) {
    let res = item.cryptoCoin;

    if (item.baseCoin) {
      res = res + '/' + item.baseCoin;
    }

    return res;
  }

  loadPage($event: number) {
    this.loadingService.openLoader();
    const skipItems = ($event - 1) * this.pageSize;

    const payments = this.userId
    ? this.userService.getUserPayments(
        this.userId,
        this.pageSize,
        skipItems,
        0,
        ''
      )
    : this.userService.getPayments(
      this.pageSize,
      skipItems,
      0,
      ''
    );

    combineLatest([payments]).pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe(res => {
      this.items = res[0].items;
      this.totalPages = Math.ceil(res[0].totalCount / this.pageSize);
    });
  }

  completePayment(id: number) {
    this.dialog.open(PopUpComponent, {
      data: {
        title: 'Are you sure?',
        message: 'This payment will be marked as completed',
        cancelButtonText: 'Cancel',
        saveButtonText: 'Confirm',
      }
    }).afterClosed().subscribe(res => {
      if (!res) return;

      this.loadingService.openLoader();
      this.userService.completePayment(id).pipe(
        finalize(() => this.loadingService.closeLoader())
      ).subscribe(_ => {
        this.loadPage(1);
      });
    });
  }

  deletePayment(id: number) {
    this.dialog.open(PopUpComponent, {
      data: {
        title: 'Are you sure?',
        message: 'This payment will be deleted permanently',
        cancelButtonText: 'Cancel',
        saveButtonText: 'Confirm',
      }
    }).afterClosed().subscribe(res => {
      if (!res) return;

      this.loadingService.openLoader();
      this.userService.deletePayment(id).pipe(
        finalize(() => this.loadingService.closeLoader())
      ).subscribe(_ => {
        this.loadPage(1);
      });
    });
  }
}
